:root {
  --primary: #00a09b;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  @apply box-border;
}

body {
  overflow-x: hidden;
}

#nav-social-container img {
  height: 20px;
  width: 100%;
}

.home-container {
  min-height: 600px;
  background-image: url('/img/banner-bg.png');
  display: flex;
  max-width: 1920px;
  align-items: center;
  position: relative;
  z-index: 1;
  background-size: cover;
  margin: -140px auto 0px;
  background-position: center center;
  background-repeat: no-repeat;
}

@screen md {
  .home-container {
    min-height: 715px;
  }
}

nav {
  z-index: 99999;
  position: relative;
}

@screen lg {
  .layer-image:before {
    @apply absolute block top-0 left-0;
    content: '';
    width: 50%;
    height: 30px;
    background-color: var(--primary);
  }
  .layer-image:after {
    @apply absolute top-0 left-0 block;
    content: '';
    width: 30px;
    height: 50%;
    background-color: var(--primary);
  }
  .layer-image img {
    @apply relative;
    padding: 30px 0 0 30px;
  }
  .newsletter-bg {
    box-shadow: 20px 20px 0px var(--primary);
  }
}

.thumbs .thumb {
  border: none !important;
}

.slick-prev:before,
.slick-next:before {
  @apply text-black !important;
}

.slick-track {
  margin-left: inherit !important;
  margin-right: inherit !important;
}

ul.thumbs {
  width: 80vw;
}

.hero {
  @apply w-full bg-no-repeat bg-cover -mt-36 flex items-center bg-center;
  min-height: 350px;
  width: 100%;
  max-width: 1920px;
  align-items: flex-end;
  padding-bottom: 1rem;
}

@screen lg {
  .hero {
    min-height: 505px;
    padding-bottom: 3rem;
  }
  .hero-title:before {
    content: '';
    width: 57%;
    right: 0;
    height: 30px;
    bottom: -1px;
    background-color: var(--primary);
    display: block;
    position: absolute;
    z-index: -1;
  }
}

.mdc-linear-progress__bar-inner {
  background-color: #1e90ff !important;
}

/*------------------------------------------------------
	Modern scrollbar styles (copied from Azure DevOps)
 ------------------------------------------------------*/
*::-webkit-scrollbar {
  width: 18px;
  height: 18px;
}
*::-webkit-scrollbar-thumb {
  border: 6px solid transparent;
  background: rgba(0, 0, 0, 0.2);
  background: var(--palette-black-alpha-20, rgba(0, 0, 0, 0.2));
  border-radius: 10px;
  background-clip: padding-box;
}
*::-webkit-scrollbar-corner {
  background: transparent;
}
*::-webkit-scrollbar-thumb:vertical {
  min-height: 30px;
}
*::-webkit-scrollbar-thumb:horizontal {
  min-width: 30px;
}
*::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
  background: var(--palette-black-alpha-30, rgba(0, 0, 0, 0.3));
  background-clip: padding-box;
  border: 4px solid transparent;
}
*.smallscroll *::-webkit-scrollbar-thumb:hover {
  border: 6px solid transparent;
}

body {
  overflow: overlay;
}

/* Range Slider */
.rc-slider-handle {
  border-color: var(--primary) !important;
  width: 20px !important;
  height: 20px !important;
  margin-top: -8px !important;
}

.rc-slider-handle-dragging {
  box-shadow: 0 0 0 5px var(--primary) !important;
}

.spin {
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}

div.gmnoprint > div > button[aria-label="Stop drawing"] {
  margin-right: -40px !important;
  margin-top: 5px !important;
}

div.gmnoprint > div > button[aria-label="Draw a circle"] {
  margin-right: -40px !important;
  margin-top: calc(0px + 40px) !important;
}

div.gmnoprint > div > button[aria-label="Draw a shape"] {
  margin-right: 5px !important;
  margin-top: calc(0px + 80px) !important;
}

div.gmnoprint > div > button[aria-label="Stop drawing"],
div.gmnoprint > div > button[aria-label="Draw a circle"],
div.gmnoprint > div > button[aria-label="Draw a shape"]
 {
  width: 40px;
  height: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

div.gmnoprint > div > button[aria-label="Stop drawing"] > span > div,
div.gmnoprint > div > button[aria-label="Draw a circle"] > span > div,
div.gmnoprint > div > button[aria-label="Draw a shape"] > span > div {
  transform: scale(1.2);
}
