.hero {
  @apply w-full bg-no-repeat bg-cover -mt-36 flex items-center bg-center;
  min-height: 350px;
  width: 100%;
  max-width: 1920px;
}

@screen lg {
  .hero {
    min-height: 505px;
  }
  .hero-title:before {
    content: "";
    width: 57%;
    right: 0;
    height: 30px;
    bottom: -1px;
    background-color: var(--primary);
    display: block;
    position: absolute;
    z-index: -1;
  }
}
